<template>
  <Signa1ExpandableList
    :label="$t('pages.signa.chooseSignum')"
    :name-map="nameMap"
    :preselected-signum1="selectedSignum1"
    :show-deprecated="true"
    @select="signumSelected"
  >
    <template v-slot:signum1="{ element: { current, signum1 } }">
      <a :name="signum1" />
      <SignaList v-if="current" :signum1="signum1" :only-primary="false" />
    </template>
  </Signa1ExpandableList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SignaTree",
  components: {
    SignaList: () => import("./SignaList"),
    Signa1ExpandableList: () => import("@/components/Signa1ExpandableList")
  },
  props: {
    selectedSignum1: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: null
    }
  },
  methods: {
    signumSelected({ signum1: { signum1 }, signum2 }) {
      this.$router.push({
        name: "signum",
        params: { signum1, signum2 }
      });
    },
    nameMap(element) {
      return {
        name: element.signum1.signum1 + " " + element.signum2,
        ...element
      };
    }
  }
};
</script>
